import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSegment } from '@repo/poly-hooks/segment';
import type { ScaEnforcementStatus } from '../components/index';
import { SCA_ENFORCEMENT_STATUS } from '../components';
import { httpClient } from '../utils/fetch-client';
import { authBaseURL } from '../utils/environment';
import type { OnSensitiveAction } from '../domain/sensitive-action';

const SCA_ENFORCEMENT_SMS_URL = `${authBaseURL}/v5/sca_enforcement/trigger_sms`;

function postSendSms({ token }: { token: string }): Promise<unknown> {
  return httpClient.post(SCA_ENFORCEMENT_SMS_URL, {
    headers: { 'X-Qonto-SCA-Enforcement-Token': token },
  });
}

export function useMutationSendSms(): UseMutationResult<unknown, Error, { token: string }> {
  return useMutation({
    mutationFn: postSendSms,
  });
}

const SCA_ENFORCEMENT_STATUS_URL = `${authBaseURL}/v5/users/sca_enrollment_status`;

function getEnforcementStatus({
  token,
}: {
  token: string;
}): Promise<{ sca_enrollment_status: ScaEnforcementStatus }> {
  return httpClient.get(SCA_ENFORCEMENT_STATUS_URL, {
    headers: { 'X-Qonto-SCA-Enforcement-Token': token },
  });
}

export function useEnforcementStatus({
  token,
  onSensitiveAction,
}: {
  token: string;
  onSensitiveAction: OnSensitiveAction;
}): UseQueryResult<{
  sca_enrollment_status: ScaEnforcementStatus;
}> {
  const segment = useSegment();

  return useQuery({
    queryKey: ['enforcement-status', token],
    queryFn: async () => {
      try {
        const enforcementStatus = await getEnforcementStatus({ token });
        if (enforcementStatus.sca_enrollment_status === SCA_ENFORCEMENT_STATUS.SUCCESS) {
          segment.track('sca_enforcement_completed');
          await onSensitiveAction();
        }
        return enforcementStatus;
      } catch (error) {
        segment.track('sca_enforcement_closed', { origin: 'authentication_failed' });
        return {
          sca_enrollment_status: SCA_ENFORCEMENT_STATUS.FAILURE,
        };
      }
    },
    enabled: Boolean(token),
    refetchInterval: ({ state: { data } }) => {
      if (
        data?.sca_enrollment_status === SCA_ENFORCEMENT_STATUS.SUCCESS ||
        data?.sca_enrollment_status === SCA_ENFORCEMENT_STATUS.FAILURE
      ) {
        return false;
      }

      return 1000;
    },
  });
}
