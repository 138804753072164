import {
  Button,
  Checkbox,
  Flag,
  Select,
  SelectOption,
  type CountryCode,
} from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { valibotResolver } from '@hookform/resolvers/valibot';
import * as v from 'valibot';
import styles from './certificate-download.strict-module.css';

interface LanguageOption {
  code: string;
  name: string;
  flag: CountryCode;
}

const COUNTRY_LANGUAGE_OPTIONS: Record<string, LanguageOption> = {
  FR: { code: 'fr', name: 'Français', flag: 'FR' },
  DE: { code: 'de', name: 'Deutsch', flag: 'DE' },
  IT: { code: 'it', name: 'Italiano', flag: 'IT' },
  ES: { code: 'es', name: 'Español', flag: 'ES' },
  PT: { code: 'pt', name: 'Português', flag: 'PT' },
  NL: { code: 'nl', name: 'Nederlands', flag: 'NL' },
  AT: { code: 'de', name: 'Deutsch', flag: 'DE' },
  BE: { code: 'fr', name: 'Français', flag: 'FR' },
};

const ENGLISH_OPTION: LanguageOption = { code: 'en', name: 'English', flag: 'GB' };

function getLanguageOptionForCountry(countryCode: string): LanguageOption {
  return COUNTRY_LANGUAGE_OPTIONS[countryCode] || ENGLISH_OPTION;
}

export interface CertificateFormValues {
  locale: string;
  withBalance: boolean;
}

export interface CertificateDownloadProps {
  countryCode: string;
  isLoading?: boolean;
  onSubmit: (values: CertificateFormValues) => void;
}

export function CertificateDownload({
  countryCode,
  isLoading = false,
  onSubmit,
}: CertificateDownloadProps): React.ReactNode {
  const { formatMessage } = useIntl();

  const countryOption = getLanguageOptionForCountry(countryCode);

  const validationArray = [countryOption, ENGLISH_OPTION];

  const formSchema = v.object({
    locale: v.picklist(
      validationArray.map(locale => locale.code),
      formatMessage({ id: 'bank_accounts.certificate.error-disclaimer' })
    ),
    withBalance: v.boolean(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CertificateFormValues>({
    resolver: valibotResolver(formSchema),
    defaultValues: {
      locale: '',
      withBalance: false,
    },
  });

  return (
    <div aria-busy={isLoading} className={styles.container} data-test-certificate-download>
      <div className={styles.content}>
        <h4 className={styles.title}>
          <FormattedMessage id="bank_accounts.certificate.title" />
        </h4>
        <p className={styles.text}>
          <FormattedMessage id="bank_accounts.certificate.subtitle" />
        </p>

        <form
          className={styles.form}
          onSubmit={async e => {
            e.preventDefault();
            await handleSubmit(onSubmit)();
          }}
        >
          <Controller
            control={control}
            name="locale"
            render={({ field: { ref: _, ...field } }) => (
              <Select
                className={styles.select}
                data-test-certificate-language-select
                errorMessage={errors.locale?.message}
                isDisabled={isLoading}
                label={formatMessage({ id: 'bank_accounts.download.selector-title' })}
                onSelectionChange={field.onChange}
                placeholder={formatMessage({ id: 'bank_accounts.download.selector-placeholder' })}
                selectedKey={field.value}
                {...field}
              >
                {validationArray.map(option => (
                  <SelectOption
                    data-test-certificate-language-option={option.code}
                    id={option.code}
                    key={option.code}
                  >
                    <div className={styles.optionContent}>
                      <Flag
                        className={styles.flagIcon}
                        code={option.flag}
                        size="small"
                        variant="square"
                      />
                      <span className={styles.optionText}>{option.name}</span>
                    </div>
                  </SelectOption>
                ))}
              </Select>
            )}
          />

          <Controller
            control={control}
            name="withBalance"
            render={({ field: { ref: _, value, ...restField } }) => (
              <Checkbox
                className={styles.checkbox}
                data-test-certificate-balance-checkbox
                isDisabled={isLoading}
                isSelected={value}
                {...restField}
              >
                <FormattedMessage id="bank_accounts.certificate.checkbox" />
              </Checkbox>
            )}
          />

          <Button
            className={styles.button}
            data-test-certificate-download-button
            data-testid="certificate-download-button"
            isDisabled={isLoading}
            isLoading={isLoading}
            size="medium"
            type="submit"
            variant="secondary"
          >
            <FormattedMessage id="bank_accounts.share.download.cta" />
          </Button>
        </form>
      </div>
    </div>
  );
}
