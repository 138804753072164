import { Button, Popup } from '@repo/design-system-kit';
import { FormattedMessage } from '@repo/domain-kit/shared';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import { ExpiredTimeSvg } from '../../../svgs/expired-time';
import styles from './risky-login.strict-module.css';

interface TimeoutProps {
  onClose?: () => void;
}

export function Timeout({ onClose }: TimeoutProps): React.ReactNode {
  const [isOpen, setIsOpen] = useState(true);
  const { formatMessage } = useIntl();

  const segment = useSegment();

  useEffect(() => {
    segment.track('device_confirmation_timeout');
  }, [segment]);

  const handleClose = (): void => {
    onClose?.();
    setIsOpen(false);
  };

  return (
    <Popup.Root
      aria-label={formatMessage({ id: 'sca.sensitive-actions.accordion.error.login-timeout' })}
      isOpen={isOpen}
      role="dialog"
    >
      <Popup.CloseButton onPress={handleClose} />
      <Popup.Header>
        <figure className={styles.illustrationWrap}>
          <ExpiredTimeSvg />
        </figure>
        <Popup.Title>
          <FormattedMessage id="sca.sensitive-actions.accordion.error.login-timeout" />
        </Popup.Title>
        <Popup.Subtitle>
          <FormattedMessage id="sca.sensitive-actions.accordion.error.login-timeout-description" />
        </Popup.Subtitle>
      </Popup.Header>
      <Popup.Footer>
        <Button onPress={handleClose} variant="primary">
          <FormattedMessage id="sca.sensitive-actions.accordion.confirm-device.try-again" />
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
