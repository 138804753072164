import { RiskyLogin, Sca } from '../popup';

export const EMAIL_VERIFICATION_STATUSES = {
  EMAIL_VERIFICATION_REQUIRED: 'email-verification-required',
  EMAIL_VERIFICATION_TIMEOUT: 'email-verification-timeout',
  ERROR: 'error',
} as const;

export type EmailVerificationStatus =
  (typeof EMAIL_VERIFICATION_STATUSES)[keyof typeof EMAIL_VERIFICATION_STATUSES];

interface EmailVerificationModalProps {
  onClose: () => void;
  status: EmailVerificationStatus;
}

export function EmailVerificationModal({
  status,
  onClose,
}: EmailVerificationModalProps): React.ReactNode {
  switch (status) {
    case EMAIL_VERIFICATION_STATUSES.EMAIL_VERIFICATION_REQUIRED:
      return <RiskyLogin.VerifyDevice onClose={onClose} />;
    case EMAIL_VERIFICATION_STATUSES.EMAIL_VERIFICATION_TIMEOUT:
      return <RiskyLogin.Timeout onClose={onClose} />;
    case EMAIL_VERIFICATION_STATUSES.ERROR:
      return <Sca.Refused onClose={onClose} onConfirm={onClose} />;
    default:
      return null;
  }
}
