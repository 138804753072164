import { type ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export interface AnimatedCellProps {
  rowIndex: number;
  cellIndex: number;
  rowToAnimate: number | null;
  children: ReactNode;
  isLoading?: boolean;
  animateFromCellIndex?: number;
  variant?: string;
  onAnimationComplete?: (variant: string) => void;
}

export function AnimatedCell({
  rowIndex,
  cellIndex,
  rowToAnimate,
  animateFromCellIndex = 0,
  children,
  isLoading = false,
  variant,
  onAnimationComplete,
  ...props
}: AnimatedCellProps): ReactNode {
  const variants = {
    hidden: { opacity: 0, x: -30 }, // Start position for the animation
    visible: (i: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.1, // Delay each row based on its index
        duration: 0.5, // Duration of the animation
        type: 'tween',
      },
    }),
    applyFlashForecast: (i: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.1, // Delay each row based on its index
        duration: 0.5, // Duration of the animation
        type: 'tween',
        '--forecast-color': {
          times: [0, 0.75, 1], // Hold first color until halfway
          duration: 1, // Total duration for color transition
        },
      },
      '--forecast-color': [
        'var(--content-accent)',
        'var(--content-accent)',
        'var(--content-secondary)',
      ],
    }),
  };

  const shouldAnimate =
    rowIndex === rowToAnimate && cellIndex >= animateFromCellIndex && !isLoading;

  return shouldAnimate ? (
    <AnimatePresence>
      <motion.div
        animate={variant ?? 'visible'} // Animate to the 'visible' variant
        custom={cellIndex} // Pass the index to the 'visible' variant for staggered animation
        initial="hidden" // Start with the 'hidden' variant
        key={`${rowIndex}-${cellIndex}`}
        onAnimationComplete={onAnimationComplete}
        variants={variants} // Apply the defined variants
        {...props}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  ) : (
    children
  );
}
