declare global {
  namespace FormatjsIntl {
    interface IntlConfig {
      locale: LocaleCode;
    }
  }
}

export const LOCALES_DEFINITION = {
  EN: { name: 'English', code: 'en' },
  FR: { name: 'Français', code: 'fr' },
  IT: { name: 'Italiano', code: 'it' },
  DE: { name: 'Deutsch', code: 'de' },
  ES: { name: 'Español', code: 'es' },
  PT: { name: 'Português', code: 'pt' },
} as const;

export const LOCALES = Object.values(LOCALES_DEFINITION);

export const DEFAULT_LOCALE = LOCALES_DEFINITION.EN.code;

export const LOCALE_CODES = LOCALES.map(locale => locale.code);
export type LocaleCode = (typeof LOCALE_CODES)[number];

/**
 * Gets the localized name for a locale code
 * @param localeCode - The locale code (e.g., 'en', 'fr')
 * @returns The localized name of the locale or the code if not found
 */
export const getLocaleName = (localeCode: string): string => {
  const localeObj = Object.values(LOCALES_DEFINITION).find(l => l.code === localeCode);
  return localeObj?.name || localeCode;
};
