import { RiskyLogin, Sca } from '../popup';

export const SCA_STATUSES = {
  ALLOW: 'allow',
  DENY: 'deny',
  WAITING: 'waiting',
  NO_PAIRED_DEVICE: 'no-paired-device',
  ERROR: 'error',
  EMAIL_VERIFICATION_REQUIRED: 'email-verification-required',
  EMAIL_VERIFICATION_TIMEOUT: 'email-verification-timeout',
} as const;

export type ScaStatus = (typeof SCA_STATUSES)[keyof typeof SCA_STATUSES];

interface ScaAuthModalProps {
  status: (typeof SCA_STATUSES)[keyof typeof SCA_STATUSES];
  onClose: () => void;
  onDenyConfirm: () => void;
}

export function ScaAuthModal({
  status,
  onClose,
  onDenyConfirm,
}: ScaAuthModalProps): React.ReactNode {
  switch (status) {
    case SCA_STATUSES.WAITING:
      return <Sca.Waiting onClose={onClose} />;
    case SCA_STATUSES.NO_PAIRED_DEVICE:
      return <Sca.NoPairedDevice onClose={onClose} />;
    case SCA_STATUSES.ERROR:
      return <Sca.GenericError onClose={onClose} />;
    case SCA_STATUSES.EMAIL_VERIFICATION_REQUIRED:
      return <RiskyLogin.VerifyDevice onClose={onClose} />;
    case SCA_STATUSES.EMAIL_VERIFICATION_TIMEOUT:
      return <RiskyLogin.Timeout onClose={onClose} />;
    case SCA_STATUSES.DENY:
      return <Sca.Refused onClose={onClose} onConfirm={onDenyConfirm} />;
    case SCA_STATUSES.ALLOW:
    default:
      return <div />;
  }
}
