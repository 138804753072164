import cx from 'clsx';
import type { ReactNode } from 'react';
import styles from './details-list.strict-module.css';

export interface DetailsItem {
  label: string;
  text: string;
}

export interface DetailsListProps {
  items: DetailsItem[];
}

export function DetailsList({ items }: DetailsListProps): ReactNode {
  return (
    <dl className={styles.details} data-test-details-list>
      {items.map((item, index) => (
        <div
          className={styles['details-row']}
          data-test-details-list-item={index}
          key={`details-item-${item.label}`}
        >
          <dt
            className={cx(styles['details-row-label'], 'body-1')}
            data-test-details-list-item-label
          >
            {item.label}
          </dt>
          <dd className={cx(styles['details-row-text'], 'body-1')} data-test-details-list-item-text>
            {item.text}
          </dd>
        </div>
      ))}
    </dl>
  );
}
