import { ToastProvider } from '@repo/design-system-kit';
import type { ComponentProps } from 'react';
import { QueryClientProvider } from './client-provider';
import { IntlProvider } from './intl-provider';
import { UserProvider } from './user-provider';
import { SegmentProvider } from './segment-provider';

export function Providers({
  children,
  user,
}: {
  children: React.ReactNode;
  user?: ComponentProps<typeof UserProvider>['user'];
}): React.ReactNode {
  return (
    <SegmentProvider>
      <QueryClientProvider>
        <UserProvider user={user}>
          <IntlProvider>
            <ToastProvider>{children}</ToastProvider>
          </IntlProvider>
        </UserProvider>
      </QueryClientProvider>
    </SegmentProvider>
  );
}
