// @generated by protobuf-ts 2.9.6 with parameter generate_dependencies,ts_nocheck,force_client_none,force_server_none
// @generated from protobuf file "qonto/international_out/eligibility/api/v1/service.proto" (package "qonto.international_out.eligibility.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * The request to check the eligibility of an organization.
 *
 * @generated from protobuf message qonto.international_out.eligibility.api.v1.GetEligibilityRequest
 */
export interface GetEligibilityRequest {
}
/**
 * The response describing the eligibility status of an organization.
 *
 * @generated from protobuf message qonto.international_out.eligibility.api.v1.GetEligibilityResponse
 */
export interface GetEligibilityResponse {
    /**
     * The eligibility status of the organization from the request.
     *
     * @generated from protobuf field: qonto.international_out.eligibility.api.v1.GetEligibilityResponse.Status status = 1;
     */
    status: GetEligibilityResponse_Status;
    /**
     * The reason why the organization is not eligible for International-Out.
     *
     * @generated from protobuf field: optional qonto.international_out.eligibility.api.v1.GetEligibilityResponse.Reason reason = 2;
     */
    reason?: GetEligibilityResponse_Reason;
}
/**
 * The eligibility status of the organization.
 *
 * @generated from protobuf enum qonto.international_out.eligibility.api.v1.GetEligibilityResponse.Status
 */
export enum GetEligibilityResponse_Status {
    /**
     * The status has not been specified
     *
     * @generated from protobuf enum value: STATUS_UNSPECIFIED = 0;
     */
    STATUS_UNSPECIFIED = 0,
    /**
     * The organization is eligible for International-Out.
     *
     * @generated from protobuf enum value: eligible = 1;
     */
    eligible = 1,
    /**
     * The organization is not eligible for International-Out.
     *
     * @generated from protobuf enum value: ineligible = 2;
     */
    ineligible = 2
}
/**
 * Collection of potential reasons why an organization is not eligible for International-Out.
 *
 * @generated from protobuf enum qonto.international_out.eligibility.api.v1.GetEligibilityResponse.Reason
 */
export enum GetEligibilityResponse_Reason {
    /**
     * The reason has not been specified.
     *
     * @generated from protobuf enum value: REASON_UNSPECIFIED = 0;
     */
    REASON_UNSPECIFIED = 0,
    /**
     * The reason is unknown or we are unable to provide this information.
     *
     * @generated from protobuf enum value: unknown = 1;
     */
    unknown = 1,
    /**
     * The organization is not approved by the Flex-KYB process.
     *
     * @generated from protobuf enum value: flex_kyb_not_approved = 2;
     */
    flex_kyb_not_approved = 2,
    /**
     * The organization is not approved by the KYB process.
     *
     * @generated from protobuf enum value: kyb_not_approved = 3;
     */
    kyb_not_approved = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class GetEligibilityRequest$Type extends MessageType<GetEligibilityRequest> {
    constructor() {
        super("qonto.international_out.eligibility.api.v1.GetEligibilityRequest", []);
    }
    create(value?: PartialMessage<GetEligibilityRequest>): GetEligibilityRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetEligibilityRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEligibilityRequest): GetEligibilityRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEligibilityRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message qonto.international_out.eligibility.api.v1.GetEligibilityRequest
 */
export const GetEligibilityRequest = new GetEligibilityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEligibilityResponse$Type extends MessageType<GetEligibilityResponse> {
    constructor() {
        super("qonto.international_out.eligibility.api.v1.GetEligibilityResponse", [
            { no: 1, name: "status", kind: "enum", T: () => ["qonto.international_out.eligibility.api.v1.GetEligibilityResponse.Status", GetEligibilityResponse_Status] },
            { no: 2, name: "reason", kind: "enum", opt: true, T: () => ["qonto.international_out.eligibility.api.v1.GetEligibilityResponse.Reason", GetEligibilityResponse_Reason] }
        ]);
    }
    create(value?: PartialMessage<GetEligibilityResponse>): GetEligibilityResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<GetEligibilityResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEligibilityResponse): GetEligibilityResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* qonto.international_out.eligibility.api.v1.GetEligibilityResponse.Status status */ 1:
                    message.status = reader.int32();
                    break;
                case /* optional qonto.international_out.eligibility.api.v1.GetEligibilityResponse.Reason reason */ 2:
                    message.reason = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEligibilityResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* qonto.international_out.eligibility.api.v1.GetEligibilityResponse.Status status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* optional qonto.international_out.eligibility.api.v1.GetEligibilityResponse.Reason reason = 2; */
        if (message.reason !== undefined)
            writer.tag(2, WireType.Varint).int32(message.reason);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message qonto.international_out.eligibility.api.v1.GetEligibilityResponse
 */
export const GetEligibilityResponse = new GetEligibilityResponse$Type();
/**
 * @generated ServiceType for protobuf service qonto.international_out.eligibility.api.v1.EligibilityService
 */
export const EligibilityService = new ServiceType("qonto.international_out.eligibility.api.v1.EligibilityService", [
    { name: "GetEligibility", options: { "qonto.http.route": { get: "/v1/eligibility" } }, I: GetEligibilityRequest, O: GetEligibilityResponse }
]);
