import { type ReactNode } from 'react';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import styles from './success.strict-module.css';

export interface SuccessProps {
  lottieSrc: string;
  title: string;
  subtitle: string;
  cta: string;
  onClick: () => void;
}

export function Success({ lottieSrc, title, subtitle, cta, onClick }: SuccessProps): ReactNode {
  return (
    <section className={styles.success}>
      <div className={styles.lottieWrapper}>
        <LottiePlayer
          className={styles.lottieIllustration}
          data-testid="topup-success-illustration"
          path={lottieSrc}
        />
      </div>
      <h1 className="title-1 mb-16" data-testid="topup-success-title">
        {title}
      </h1>
      <p className="body-1 mb-32" data-testid="topup-success-subtitle">
        {subtitle}
      </p>
      <Button data-testid="topup-success-cta" onPress={onClick}>
        {cta}
      </Button>
    </section>
  );
}
