import { clsx } from 'clsx';
import { type ReactNode, Suspense } from 'react';
import { useIntl } from 'react-intl';
import * as QRCode from 'qrcode';
import { IconEmptystateUpgrade } from '../../../../assets/icons/icon-emptystate-upgrade.tsx';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import styles from './payment-link.strict-module.css';

interface PaymentLinkProps {
  paymentLink?: string | null;
  className?: string;
}

export function PaymentLink({ paymentLink, className }: PaymentLinkProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage } = usePdfPreviewContext();

  if (paymentLink) {
    return (
      <div className={clsx(styles.wrapper, className)} data-testid="pdf-preview-payment-link">
        <figure className={styles.qrCodeContainer}>
          <Suspense fallback={<div className={styles.qrCode} />}>
            <QrCode paymentLink={paymentLink} />
          </Suspense>
          <figcaption className="caption" data-testid="pdf-preview-payment-link-title">
            {formatMessage(
              { id: 'pdf-preview.payment-footer.text' },
              { language: displayLanguage }
            )}
          </figcaption>
        </figure>
        <a
          className={styles.paymentCta}
          data-testid="pdf-preview-payment-link-cta"
          href={paymentLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <IconEmptystateUpgrade aria-hidden="true" />
          {formatMessage({ id: 'pdf-preview.payment-footer.cta' }, { language: displayLanguage })}
        </a>
      </div>
    );
  }

  return null;
}

const qrCodeCache = new Map<string, string>();

function useSuspenseQrCode(src: string): string {
  const qrCodeDataUrl = qrCodeCache.get(src);

  if (!qrCodeDataUrl) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal -- we throw a Promise to trigger Suspense
    throw new Promise(resolve => {
      QRCode.toDataURL(src)
        .then(dataURL => {
          qrCodeCache.set(src, dataURL);
          resolve(null);
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  return qrCodeDataUrl;
}

interface QrCodeProps {
  paymentLink: string;
}

function QrCode({ paymentLink }: QrCodeProps): ReactNode {
  const qrCodeDataUrl = useSuspenseQrCode(paymentLink);

  return (
    <img
      alt=""
      className={styles.qrCode}
      data-testid="pdf-preview-payment-link-qr-code"
      src={qrCodeDataUrl}
    />
  );
}
