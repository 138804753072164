import * as React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { clsx } from 'clsx';
import styles from './carousel.strict-module.css';
import { Dots } from './dots.tsx';

type EmblaCarouselOptions = Parameters<typeof useEmblaCarousel>[0];

export type CarouselOptions = EmblaCarouselOptions & {
  hideDots?: boolean;
};

export interface CarouselActions {
  scrollPrev: () => void;
  scrollNext: () => void;
}

interface CarouselProps {
  children: React.ReactNode;
  slideSize?: number | string;
  slideSpacing?: number | string;
  options?: CarouselOptions;
  onIndexChange?: (index: number) => void;
  showDots?: boolean;
}

export const Carousel = React.forwardRef(function Carousel(
  {
    children,
    slideSize = '100%',
    slideSpacing = '1rem',
    options = { align: 'center', hideDots: false },
    onIndexChange,
  }: CarouselProps,
  ref: React.Ref<CarouselActions>
): React.JSX.Element {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [index, setIndex] = React.useState(0);
  const slides = React.Children.toArray(children).length;

  const onScroll = React.useCallback(() => {
    const progress = emblaApi?.scrollProgress() ?? 0;
    const newIndex = Math.round(progress * (slides - 1));
    setIndex(newIndex);
  }, [emblaApi, slides]);

  React.useEffect(() => {
    if (onIndexChange) {
      onIndexChange(index);
    }
  }, [index, onIndexChange]);

  React.useEffect(() => {
    emblaApi?.on('scroll', onScroll);
    emblaApi?.on('init', onScroll);
  }, [emblaApi, onScroll]);

  // Expose scrollPrev and scrollNext methods via ref
  React.useImperativeHandle(ref, () => ({
    scrollPrev: () => emblaApi?.scrollPrev(),
    scrollNext: () => emblaApi?.scrollNext(),
  }));

  return (
    <>
      {!options.hideDots ? <Dots count={slides} index={index} /> : null}

      <div
        ref={emblaRef}
        style={
          {
            '--slide-size': slideSize,
            '--slide-spacing': slideSpacing,
          } as React.CSSProperties
        }
      >
        <div className={styles.emblaContainer}>{children}</div>
      </div>
    </>
  );
});

export function CarouselItem({
  className,
  children,
}: {
  children: React.ReactNode;
  className?: string;
}): React.JSX.Element {
  return <div className={clsx(styles.emblaSlide, className)}>{children}</div>;
}
