import type { ReactElement, SVGProps } from 'react';

export function IconPaypal(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height={40}
      viewBox="0 0 40 40"
      width={40}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#F9F9F7" height={40} rx={4} width={40} />
      <path
        d="M25.265 16.81c-.014.09-.03.18-.047.274-.604 3.157-2.673 4.247-5.314 4.247H18.56a.658.658 0 0 0-.646.564l-.688 4.441-.196 1.26a.356.356 0 0 0 .079.281.34.34 0 0 0 .261.123h2.385a.577.577 0 0 0 .568-.492l.023-.124.45-2.899.028-.159a.578.578 0 0 1 .567-.493h.357c2.31 0 4.12-.954 4.649-3.716.221-1.153.107-2.117-.478-2.794a2.279 2.279 0 0 0-.653-.512Z"
        stroke="#000"
      />
      <path
        d="M18.699 16.827a.574.574 0 0 1 .566-.493h3.594c.425 0 .822.03 1.185.089.245.038.487.097.723.175.18.06.344.131.498.213.18-1.166-.002-1.96-.622-2.68C23.96 13.339 22.725 13 21.146 13h-4.584a.659.659 0 0 0-.648.563l-1.91 12.31a.41.41 0 0 0 .09.323c.075.089.185.14.299.14h2.83l.712-4.585.764-4.924Z"
        stroke="#000"
      />
    </svg>
  );
}
