import cx from 'clsx';
import type { HTMLProps, ReactElement } from 'react';
import { IconCheckmarkRoundedFilledSmall } from '../../../assets/icons/icon-checkmark-rounded-filled-small';
import { IconClockFilledSmall } from '../../../assets/icons/icon-clock-filled-small';
import { IconErrorFilledSmall } from '../../../assets/icons/icon-error-filled-small';
import { IconProcessingFilled } from '../../../assets/icons/icon-processing-filled';
import { IconWarningSmall } from '../../../assets/icons/icon-warning-small';
import { IconDeclinedSmall } from '../../../assets/icons/icon-declined-small';
import styles from './status.strict-module.css';

export type StatusType =
  | 'inactive'
  | 'error'
  | 'in-progress'
  | 'validated'
  | 'waiting'
  | 'warning'
  | 'declined';

export interface StatusProps extends HTMLProps<HTMLSpanElement> {
  level?: StatusType;
  text: string;
  className?: string;
}

const ICONS = {
  error: <IconErrorFilledSmall />,
  inactive: null,
  'in-progress': <IconProcessingFilled />,
  validated: <IconCheckmarkRoundedFilledSmall />,
  waiting: <IconClockFilledSmall />,
  warning: <IconWarningSmall />,
  declined: <IconDeclinedSmall />,
} as const;

export function BadgeStatus({
  level = 'waiting',
  text,
  className,
  ...props
}: StatusProps): ReactElement {
  return (
    <span
      className={cx('caption', styles.status, level === 'inactive' && styles.inactive, className)}
      data-test-badge-status=""
      data-test-level={level}
      {...props}
    >
      {Boolean(ICONS[level]) && (
        <span className={cx(styles.icon, styles[level])}>{ICONS[level]}</span>
      )}
      {text}
    </span>
  );
}
