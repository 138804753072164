import type { ReactElement, SVGProps } from 'react';

export function IconCreditCard(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height={40}
      viewBox="0 0 40 40"
      width={40}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#F9F9F7" height={40} rx={4} width={40} />
      <path d="M12 12h16v16H12z" fill="#F9F9F7" />
      <path
        d="M24.5 24h-2c-.86 0-1.5-.64-1.5-1.5s.64-1.5 1.5-1.5h2c.86 0 1.5.64 1.5 1.5s-.64 1.5-1.5 1.5Zm-2-2c-.37 0-.5.26-.5.5s.13.5.5.5h2c.37 0 .5-.26.5-.5s-.13-.5-.5-.5h-2Z"
        fill="#1D1D1B"
      />
      <path
        d="M26.8 14H13.2c-.66 0-1.2.53-1.2 1.2v9.6c0 .66.54 1.2 1.2 1.2h13.6c.66 0 1.2-.54 1.2-1.2v-9.6c0-.67-.54-1.2-1.2-1.2Zm.2 10.8c0 .1-.09.2-.2.2H13.2c-.11 0-.2-.1-.2-.2V18h14v6.8ZM13 17v-1.8c0-.11.09-.2.2-.2h13.6c.11 0 .2.09.2.2V17H13Z"
        fill="#1D1D1B"
      />
    </svg>
  );
}
