import { useEffect, useState } from 'react';
import { emailVerificationChannel } from '../utils/broadcast-channel';
import {
  EmailVerificationModal,
  EMAIL_VERIFICATION_STATUSES,
  type EmailVerificationStatus,
} from '../components';
import type { ErrorComponentProps } from './sensitive-action';

const EMAIL_VERIFICATION_TIMEOUT = 5 * 60 * 1000;

export function EmailVerificationUI({
  onClose,
  onSensitiveAction,
}: ErrorComponentProps): JSX.Element {
  const [emailVerificationStatus, setEmailVerificationStatus] = useState<EmailVerificationStatus>(
    EMAIL_VERIFICATION_STATUSES.EMAIL_VERIFICATION_REQUIRED
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEmailVerificationStatus(EMAIL_VERIFICATION_STATUSES.EMAIL_VERIFICATION_TIMEOUT);
    }, EMAIL_VERIFICATION_TIMEOUT);

    const cleanupListener = emailVerificationChannel.onMessage(async ({ action }) => {
      switch (action) {
        case 'confirm-device-success':
          await onSensitiveAction();
          break;
        case 'confirm-device-error':
          setEmailVerificationStatus(EMAIL_VERIFICATION_STATUSES.ERROR);
          break;
      }
      clearTimeout(timeout);
    });

    return () => {
      cleanupListener();
      clearTimeout(timeout);
    };
  }, [onSensitiveAction]);

  return <EmailVerificationModal onClose={onClose} status={emailVerificationStatus} />;
}
