import { useScaAuthStatus } from '../api/sca-auth';
import { SCA_STATUSES, ScaAuthModal } from '../components';
import { NO_TRUSTED_DEVICES } from '../utils/sca-errors';
import type { ErrorComponentProps } from './sensitive-action';

export function ScaAuthUI({
  onClose,
  errorDetails,
  onSensitiveAction,
}: ErrorComponentProps): JSX.Element {
  const { data: scaAuthStatus } = useScaAuthStatus({
    token: errorDetails.scaSessionToken as string,
    onSensitiveAction,
  });
  return (
    <ScaAuthModal
      onClose={onClose}
      onDenyConfirm={onClose}
      status={
        errorDetails.code === NO_TRUSTED_DEVICES
          ? SCA_STATUSES.NO_PAIRED_DEVICE
          : scaAuthStatus?.result || SCA_STATUSES.WAITING
      }
    />
  );
}
