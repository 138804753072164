/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import { CockpitTile, InfoIcon, Tooltip } from '@repo/design-system-kit';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import styles from './overview-box.strict-module.css';

interface OverviewBoxProps {
  amount?: { value: string; currency: string };
  amountSubtitle?: { value: string; currency: string; copy: string };
  isLoading?: boolean;
  isError?: boolean;
  tooltipContent?: string;
  titleText: string;
  shouldBlurText?: boolean;
}

export function OverviewBox({
  amount,
  isLoading = false,
  isError = false,
  tooltipContent = '',
  titleText = '',
  amountSubtitle,
  shouldBlurText,
}: OverviewBoxProps): ReactNode {
  const formattedAmount = (
    <span className={styles['amount-container']} data-testid="overview-amount">
      {amount && !isError ? (
        <>
          <FormattedNumber
            currency={amount.currency}
            style="currency"
            value={Number(amount.value)}
          />
          {amountSubtitle ? (
            <span className="body-2" data-testid="amount-subtitle">
              <FormattedNumber
                currency={amountSubtitle.currency}
                style="currency"
                value={Number(amountSubtitle.value)}
              />{' '}
              <FormattedMessage id={amountSubtitle.copy} />
            </span>
          ) : null}
        </>
      ) : (
        '--'
      )}
    </span>
  );

  const title = (
    <span className={styles['title-container']}>
      <span className={styles.title} data-testid="overview-title">
        <FormattedMessage id={titleText} />
      </span>
      {tooltipContent ? (
        <Tooltip label={<span dangerouslySetInnerHTML={{ __html: tooltipContent }} />} needsButton>
          <InfoIcon data-testid="overview-tooltip-trigger" height={12} width={12} />
        </Tooltip>
      ) : null}
    </span>
  );

  return (
    <CockpitTile
      amount={formattedAmount}
      isAmountLoading={isLoading}
      shouldBlurText={shouldBlurText}
      title={title}
    />
  );
}
