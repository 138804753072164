import type { ReactElement } from 'react';
import cx from 'clsx';
import { SkeletonLoader } from '../skeleton-loader';
import styles from './cockpit-tile.strict-module.css';

interface CockpitTileProps {
  title?: ReactElement | string;
  subtitle?: ReactElement | string;
  amount?: ReactElement | string;
  isAmountLoading?: boolean;
  isSubtitleLoading?: boolean;
  onClick?: () => void;
  shouldBlurText?: boolean;
}

export function CockpitTile({
  title,
  subtitle,
  amount,
  isAmountLoading,
  isSubtitleLoading,
  onClick,
  shouldBlurText,
}: CockpitTileProps): ReactElement {
  const isClickable = Boolean(onClick);
  const Element = isClickable ? 'button' : 'div';
  const buttonProps = { type: 'button' as HTMLButtonElement['type'], onClick };
  const blurTextClass = shouldBlurText ? styles['blur-text'] : '';

  return (
    <Element
      className={cx(styles['cockpit-tile'], !shouldBlurText && styles['cockpit-tile-width'])}
      data-test-cockpit-tile
      {...(isClickable && buttonProps)}
    >
      {title ? <p className={cx('title-4', styles.title)}>{title}</p> : null}

      <div>
        {isAmountLoading ? (
          <div className={styles['value-loading']} data-test-cockpit-tile-value-loading>
            <SkeletonLoader.Line width="128px" />
          </div>
        ) : (
          amount && <p className={cx('title-3', blurTextClass)}>{amount}</p>
        )}

        {isSubtitleLoading ? (
          <div className={styles['subtitle-loading']} data-test-cockpit-tile-subtitle-loading>
            <SkeletonLoader.Line width="80px" />
          </div>
        ) : (
          subtitle && <p className={cx('body-2', styles.subtitle, blurTextClass)}>{subtitle}</p>
        )}
      </div>
    </Element>
  );
}
