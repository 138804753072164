import * as React from 'react';
import { useIntl } from 'react-intl';
import { Badge, Toggle } from '@repo/design-system-kit';
import { CheckoutHeader } from '../checkout-header';
import styles from './suggested-products.strict-module.css';

interface SuggestedProductsProps {
  products: SuggestedProduct[];
  onToggle: (isSelected: boolean, product: SuggestedProduct) => void;
  title: string;
}
export interface SuggestedProduct {
  title: string;
  code: string;
  description: string;
  price: {
    value: number | string;
    currency: string;
  };
  priceNote: string;
  isSelected?: boolean;
  isFree?: boolean;
}

export function SuggestedProducts({
  title,
  products,
  onToggle,
}: SuggestedProductsProps): React.JSX.Element {
  const { formatMessage, formatNumber } = useIntl();
  return (
    <div className={styles.container} data-test-suggested-products>
      <h2 className="title-3" data-test-suggested-products-title>
        {title}
      </h2>
      <div className={styles.products}>
        {products.map(product => (
          <div data-test-suggested-products-item={product.code} key={product.code}>
            <CheckoutHeader
              elevation="high"
              key={product.title}
              price={formatNumber(Number(product.price.value), {
                style: 'currency',
                currency: product.price.currency,
              })}
              priceNote={
                <div className={styles.priceNoteContainer}>
                  <span className={styles.priceNote}>{product.priceNote}</span>
                  <Toggle
                    aria-label={
                      product.isSelected
                        ? formatMessage({ id: 'a11y.buttons.delete-aria-label' })
                        : formatMessage({ id: 'a11y.buttons.add-aria-label' })
                    }
                    checked={product.isSelected}
                    data-test-toggle-button
                    onChange={isSelected => {
                      onToggle(isSelected, product);
                    }}
                  />
                </div>
              }
              subtitle={product.description}
              title={
                <div className={styles.titleContainer}>
                  <span>{product.title}</span>
                  {product.isFree ? (
                    <Badge text={formatMessage({ id: 'upsell.tryForFree' })} type="tryForFree" />
                  ) : null}
                </div>
              }
              withBorder
            />
          </div>
        ))}
      </div>
    </div>
  );
}
