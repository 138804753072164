import type { ReactElement, SVGProps } from 'react';

export function IconApplePay(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height={40}
      viewBox="0 0 40 40"
      width={40}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#F9F9F7" height={40} rx={4} width={40} />
      <path
        d="M18.773 16.027c1.496 0 2.538.957 2.538 2.35 0 1.396-1.063 2.358-2.575 2.358h-1.657v2.443h-1.196v-7.15h2.89Zm-1.694 3.776h1.374c1.041 0 1.634-.52 1.634-1.422 0-.902-.593-1.417-1.629-1.417H17.08v2.84ZM21.624 21.696c0-.912.753-1.472 2.089-1.54l1.538-.085v-.401c0-.58-.422-.927-1.127-.927-.668 0-1.085.297-1.186.763h-1.09c.064-.942.93-1.635 2.319-1.635 1.362 0 2.233.669 2.233 1.714v3.593h-1.106v-.857h-.027c-.325.58-1.036.946-1.773.946-1.101 0-1.87-.634-1.87-1.57Zm3.627-.47v-.412l-1.384.08c-.689.044-1.079.327-1.079.773 0 .455.406.753 1.026.753.807 0 1.437-.516 1.437-1.195ZM27.444 25.096v-.868c.085.02.278.02.374.02.534 0 .823-.208 1-.743 0-.01.1-.317.1-.322l-2.03-5.218h1.25l1.422 4.242h.021l1.421-4.242h1.219l-2.106 5.485c-.48 1.264-1.036 1.67-2.2 1.67-.097 0-.386-.01-.47-.024ZM12.352 16.727c.289-.334.485-.784.433-1.243-.422.02-.937.259-1.236.594-.268.286-.505.754-.443 1.194.474.038.948-.22 1.246-.545ZM12.78 17.358c-.689-.038-1.274.362-1.602.362-.33 0-.833-.343-1.377-.334a2.056 2.056 0 0 0-1.726.973c-.74 1.182-.195 2.935.524 3.898.35.477.77 1.001 1.325.982.524-.019.73-.314 1.367-.314.636 0 .821.314 1.376.305.575-.01.934-.477 1.284-.954.4-.543.564-1.067.575-1.096-.01-.01-1.11-.4-1.12-1.573-.01-.981.863-1.448.904-1.477-.493-.677-1.263-.753-1.53-.772Z"
        fill="#000"
      />
    </svg>
  );
}
