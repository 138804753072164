import { useState, type ReactElement, type FormEvent } from 'react';
import { AmountField, Button } from '@repo/design-system-kit';
import { Form } from 'react-aria-components';
import type { Amount as AmountType } from '../../../../../types/amount';
import styles from './amount.strict-module.css';

export interface AmountProps {
  title: string;
  subtitle: string;
  cta: string;
  amountLabel: string;
  defaultAmount?: AmountType<string>;
  onSubmit: (amount: string) => void;
  onChange: () => void;
  errorMessage?: string;
}

export function Amount({
  defaultAmount,
  onSubmit,
  title,
  subtitle,
  cta,
  amountLabel,
  errorMessage,
  onChange,
  ...props
}: AmountProps): ReactElement {
  const [amount, setAmount] = useState(defaultAmount?.value ?? '');

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSubmit(amount);
  };

  const handleAmountChange = (value: string): void => {
    setAmount(value);
    onChange();
  };

  return (
    <section className={styles.container} {...props}>
      <h1 className="title-2 mb-16" data-testid="amount-title">
        {title}
      </h1>
      <p className="body-2 mb-32" data-testid="amount-subtitle">
        {subtitle}
      </p>
      <Form className={styles.form} onSubmit={handleSubmit}>
        <AmountField
          amount={amount}
          className={styles.input}
          currency={defaultAmount?.currency}
          data-testid="amount-field"
          errorMessage={errorMessage}
          label={amountLabel}
          name="amount"
          onAmountChange={handleAmountChange}
        />
        <Button data-testid="amount-submit" type="submit">
          {cta}
        </Button>
      </Form>
    </section>
  );
}
