import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  type BarProps,
  LabelList,
  type DotProps,
} from 'recharts';
import { useEffect, useMemo, useRef, useState } from 'react';
import { type FormatNumberOptions, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import styles from './combo-chart.strict-module.css';
import { CustomTooltip } from './custom-tooltip/tooltip';
import { useHighlightIndex } from './highlight-index';

interface CustomDotProps {
  cx: number;
  cy: number;
  index: number;
}

const customActiveDot = (props: DotProps): JSX.Element => {
  const { cx, cy } = props;
  return (
    <g>
      {/* Outer Gray Border */}
      <circle className={styles['selected-circle-border']} cx={cx} cy={cy} r={6} strokeWidth={4} />
      {/* Inner Purple Border */}
      <circle
        className={styles['selected-circle']}
        cx={cx}
        cy={cy}
        r={4}
        stroke="#9B81F6"
        strokeWidth={2}
      />
      {/* White Center */}
      <circle className={styles['selected-circle']} cx={cx} cy={cy} r={2} />
    </g>
  );
};

const renderFirstDotActive = (props: CustomDotProps): JSX.Element => {
  const { cx, cy, index } = props;

  if (index === 0) {
    return <circle className={styles['today-circle']} cx={cx} cy={cy} r={4} />;
  }
  return <circle />;
};

const renderFirstDotGrey = (props: CustomDotProps): JSX.Element => {
  const { cx, cy, index } = props;

  if (index === 0) {
    return <circle className={styles['grey-circle']} cx={cx} cy={cy} r={4} />;
  }
  return <circle />;
};

interface CustomTickProps {
  x: number;
  y: number;
  payload: { value: string | number };
  index: number;
  dataLength: number;
  formatNumber: (value: number, options?: FormatNumberOptions) => string;
  visibleTicks?: number[];
}

const xAxisTick = ({ x, y, payload, index, visibleTicks }: CustomTickProps): JSX.Element => {
  const shouldShowTick = visibleTicks?.includes(index);
  const formattedDate = dayjs(payload.value).format('MMM D');

  return (
    <>
      {/* Tick line for all ticks */}
      <line className={styles['x-axis-tick-line']} x1={x} x2={x} y1={y} y2={y + 4} />
      {/* Show text only for first tick */}
      {shouldShowTick ? (
        <text className={styles['x-axis-tick-text']} textAnchor="middle" x={x} y={y + 20}>
          {formattedDate}
        </text>
      ) : null}
    </>
  );
};

const yAxisTick = ({ x, y, payload, formatNumber }: CustomTickProps): JSX.Element => (
  <text className={styles['y-axis-tick-text']} x={x - 40} y={y}>
    {formatNumber(Number(payload.value), {
      style: 'currency',
      currency: 'EUR',
      notation: 'compact',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    })}
  </text>
);

const loadingYAxisTick = ({ x, y }: CustomTickProps): JSX.Element => (
  <g data-testid="skeleton-tick" transform={`translate(${x - 20}, ${y - 5})`}>
    <rect className={styles['skeleton-bar']} height="8" rx="4" ry="12" width="30" />
  </g>
);

const loadingData = [
  { name: 'Item 1', inflows: 500, outflows: 300 },
  { name: 'Item 2', inflows: 700, outflows: 400 },
  { name: 'Item 3', inflows: 600, outflows: 500 },
  { name: 'Item 4', inflows: 800, outflows: 350 },
  { name: 'Item 5', inflows: 750, outflows: 300 },
  { name: 'Item 6', inflows: 650, outflows: 550 },
  { name: 'Item 7', inflows: 500, outflows: 400 },
  { name: 'Item 8', inflows: 850, outflows: 450 },
  { name: 'Item 9', inflows: 950, outflows: 500 },
  { name: 'Item 10', inflows: 550, outflows: 200 },
  { name: 'Item 11', inflows: 600, outflows: 250 },
  { name: 'Item 12', inflows: 750, outflows: 200 },
  { name: 'Item 13', inflows: 900, outflows: 600 },
  { name: 'Item 14', inflows: 650, outflows: 300 },
  { name: 'Item 15', inflows: 700, outflows: 400 },
  { name: 'Item 16', inflows: 550, outflows: 450 },
  { name: 'Item 17', inflows: 500, outflows: 200 },
  { name: 'Item 18', inflows: 800, outflows: 300 },
  { name: 'Item 19', inflows: 900, outflows: 400 },
  { name: 'Item 20', inflows: 700, outflows: 350 },
  { name: 'Item 21', inflows: 600, outflows: 500 },
  { name: 'Item 22', inflows: 850, outflows: 200 },
  { name: 'Item 23', inflows: 950, outflows: 400 },
  { name: 'Item 24', inflows: 700, outflows: 500 },
  { name: 'Item 25', inflows: 800, outflows: 450 },
  { name: 'Item 26', inflows: 650, outflows: 600 },
  { name: 'Item 27', inflows: 600, outflows: 350 },
  { name: 'Item 28', inflows: 700, outflows: 300 },
  { name: 'Item 29', inflows: 800, outflows: 200 },
  { name: 'Item 30', inflows: 950, outflows: 450 },
];
function FlowBar({
  x,
  y,
  width,
  height,
  fill,
  dataKey,
  index,
  opacity,
  hoveredKey,
  hoveredIndex,
  setHoveredKey,
  setHoveredIndex,
}: BarProps & {
  hoveredKey: string | null;
  hoveredIndex: number | null;
  setHoveredKey: (key: string | null) => void;
  setHoveredIndex: (index: number | null) => void;
}): JSX.Element {
  const isHovered = hoveredKey === dataKey && hoveredIndex === index;

  return (
    <rect
      fill={fill}
      height={height}
      onMouseEnter={() => {
        setHoveredIndex(index as number);
        setHoveredKey(dataKey as string);
      }}
      onMouseLeave={() => {
        setHoveredKey(null);
        setHoveredIndex(null);
      }}
      opacity={opacity ?? (isHovered ? 1 : 0.5)}
      rx={1}
      ry={1}
      style={{ transition: 'opacity 0.2s ease-in-out' }}
      width={width}
      x={x}
      y={y}
    />
  );
}

type FlowType = 'inflows' | 'outflows' | 'inflows_outflows';

export interface ScheduledTransactionPreview {
  newDate: string;
  amount: string;
  type: 'inflows' | 'outflows';
  counterparty_name?: string;
  metadata?: {
    logo?: string;
  };
}

export interface ChartData {
  name: string;
  balance: number;
  inflows: number;
  outflows: number;
  metadata: { logo_url: string };
  amount: { value: string; currency: string };
  side: 'credit' | 'debit';
  date: string;
  predicted_balance: {
    currency: string;
    value: string;
  };
  total_transactions?: number;
  total_amount_for_the_day: {
    currency: string;
    value: string;
  };
}

export interface ComboChartProps {
  adjustedOutflowAmount?: string | null;
  adjustedOutflowDate?: {
    date: string | null;
    amount: string | null;
    originalDate: string | null;
    side: string | null;
  };
  scheduledTransactionPreview?: ScheduledTransactionPreview | null;
  isLoading?: boolean;
  onPeriodClick?: (period: string, flow: FlowType) => void;
  showFirstDot?: boolean;
  visibleTicks?: number[];
  onHover?: (date: string, flow: FlowType) => void;
  highlightedIndex?: number;
  data: ChartData[];
}

export function ComboChart({
  adjustedOutflowAmount,
  adjustedOutflowDate,
  scheduledTransactionPreview,
  data,
  onPeriodClick,
  isLoading = false,
  showFirstDot = true,
  visibleTicks = [0, data.length - 1],
  onHover,
  highlightedIndex,
}: ComboChartProps): JSX.Element {
  const [minY, setMinY] = useState<number>(0);
  const [maxY, setMaxY] = useState<number>(0);

  const customXAxisTick = useMemo(() => {
    return (props: CustomTickProps) => {
      return xAxisTick({ ...props, dataLength: data.length, visibleTicks });
    };
  }, [data.length, visibleTicks]);

  const balanceAdjustment = useMemo(() => {
    const amountToAdjust =
      adjustedOutflowAmount || (adjustedOutflowDate?.date ? adjustedOutflowDate.amount : null);

    if (!amountToAdjust) {
      return undefined;
    }

    const parsedValue = parseFloat(amountToAdjust);
    return isNaN(parsedValue) ? 0 : parsedValue;
  }, [adjustedOutflowAmount, adjustedOutflowDate]);

  const dataWithAdjustedBalance = useMemo(() => {
    if (balanceAdjustment === undefined) {
      const yMax = Math.max(...data.map(d => Number(d.balance)));
      const domainMax = yMax * 1.05;
      const yMin = Math.min(...data.map(d => Number(d.balance)));
      const domainMin = yMin < 0 ? yMin * 1.15 : 0;

      setMinY(domainMin);
      setMaxY(domainMax);

      return data;
    }

    const updatedDateIndex = data.findIndex(i => i.date === adjustedOutflowDate?.date);
    const originalDateIndex = data.findIndex(i => i.date === adjustedOutflowDate?.originalDate);

    let adjustedData;

    if (updatedDateIndex === -1 || !adjustedOutflowDate) {
      const totalBalance = Math.max(...data.map(d => Number(d.balance)));
      const adjustedTotalBalance = totalBalance - balanceAdjustment;

      adjustedData = data.map((item, index) => ({
        ...item,
        adjustedBalance: (index > updatedDateIndex
          ? Number(item.balance) * (adjustedTotalBalance / totalBalance)
          : Number(item.balance)
        ).toFixed(2),
      }));
    } else {
      const side = adjustedOutflowDate.side;

      const selectedTransactionAmount = Number(adjustedOutflowDate.amount);
      const totalBalance = Math.max(...data.map(d => Number(d.balance)));
      const adjustedTotalBalanceBefore =
        side === 'credit'
          ? totalBalance - selectedTransactionAmount
          : totalBalance + selectedTransactionAmount;

      const adjustedTotalBalanceAfter =
        side === 'credit'
          ? totalBalance + selectedTransactionAmount
          : totalBalance - selectedTransactionAmount;
      const highestIndex = Math.max(originalDateIndex, updatedDateIndex);

      adjustedData = data.map((item, index) => {
        const originalBalance = Number(item.balance);

        if (
          index < Math.min(updatedDateIndex, originalDateIndex) ||
          (index === updatedDateIndex && updatedDateIndex > originalDateIndex) ||
          (index === originalDateIndex && updatedDateIndex < originalDateIndex) ||
          index > highestIndex ||
          updatedDateIndex === originalDateIndex
        ) {
          return { ...item, adjustedBalance: originalBalance };
        }

        const scaleFactor =
          index < updatedDateIndex
            ? adjustedTotalBalanceBefore / totalBalance
            : adjustedTotalBalanceAfter / totalBalance;

        return {
          ...item,
          adjustedBalance: (originalBalance * scaleFactor).toFixed(2),
        };
      });
    }

    const allBalances = adjustedData.flatMap(item => [
      Number(item.balance),
      Number(item.adjustedBalance),
    ]);
    setMinY(Math.min(...allBalances));
    setMaxY(Math.max(...allBalances));

    return adjustedData;
  }, [data, balanceAdjustment, adjustedOutflowDate]);

  const dataWithAdjustedFlow = useMemo(() => {
    if (!scheduledTransactionPreview) {
      return dataWithAdjustedBalance;
    }

    const { newDate, amount, type } = scheduledTransactionPreview;

    const adjustedData = dataWithAdjustedBalance.map(item => {
      const hasAdjustedFlow = newDate === item.date;
      const adjustedInflows = hasAdjustedFlow && type === 'inflows' ? amount : null;
      const adjustedOutflows = hasAdjustedFlow && type === 'outflows' ? amount : null;

      return {
        ...item,
        adjusted_inflows: adjustedInflows,
        adjusted_outflows: adjustedOutflows,
      };
    });

    return adjustedData;
  }, [dataWithAdjustedBalance, scheduledTransactionPreview]);

  const barConfigs = [
    { dataKey: 'inflows', className: styles['inflow-bar'], testId: 'inflow-bar' },
    {
      dataKey: 'adjusted_inflows',
      className: styles['inflow-bar'],
      testId: 'adjusted-inflow-bar',
    },
    { dataKey: 'outflows', className: styles['outflow-bar'], testId: 'outflow-bar' },
    {
      dataKey: 'adjusted_outflows',
      className: styles['outflow-bar'],
      testId: 'adjusted-outflow-bar',
    },
  ];
  const inflowsBarStack = ['inflows', 'adjusted_inflows'];
  const adjustedBarStack = ['adjusted_inflows', 'adjusted_outflows'];

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [hoveredKey, setHoveredKey] = useState<string | null>(null);

  const [activeTooltipIndex, setActiveTooltipIndex] = useState<number | null>(null);

  const { formatNumber } = useIntl();

  // Calculate cursor stroke width based on data length
  const calculateDynamicSize = (): { cursorStrokeWidth: number; barSize: number } => {
    const minStrokeWidth = 15;
    const maxStrokeWidth = 35;

    const minBarSize = 4;
    const maxBarSize = 8;

    let strokeWidth, barWidth;

    if (data.length <= 31) {
      strokeWidth = maxStrokeWidth;
      barWidth = maxBarSize;
    } else {
      strokeWidth = minStrokeWidth;
      barWidth = minBarSize;
    }

    return { cursorStrokeWidth: strokeWidth, barSize: barWidth };
  };

  const { cursorStrokeWidth, barSize } = calculateDynamicSize();
  const chartRef = useRef<{ container: HTMLElement }>(null);

  const highlightIndex = useHighlightIndex(chartRef, highlightedIndex);
  let balanceDot;

  if (!showFirstDot) {
    balanceDot = false;
  } else if (balanceAdjustment !== undefined) {
    balanceDot = renderFirstDotGrey;
  } else {
    balanceDot = renderFirstDotActive;
  }

  // Use the highlightedIndex prop if provided
  useEffect(() => {
    if (highlightedIndex !== undefined) {
      setActiveTooltipIndex(highlightedIndex);
      highlightIndex();
    }
  }, [highlightedIndex, highlightIndex]);

  return (
    <div style={{ width: '100%', height: '156px' }}>
      <ResponsiveContainer height="100%" width="100%">
        <ComposedChart
          data={isLoading ? loadingData : dataWithAdjustedFlow}
          margin={{ top: 20 }}
          onClick={e => {
            const flow = hoveredKey ? hoveredKey : 'inflows_outflows';
            onPeriodClick?.(
              data[e.activeTooltipIndex ?? 0]?.date ?? dayjs().format('MMM D'),
              flow as FlowType
            );
          }}
          onMouseMove={e => {
            if (e.activeTooltipIndex !== undefined && e.activeTooltipIndex !== activeTooltipIndex) {
              setActiveTooltipIndex(e.activeTooltipIndex);
              const hoveredDate = data[e.activeTooltipIndex ?? 0]?.date ?? dayjs().format('MMM D');
              const flow = hoveredKey ? hoveredKey : 'inflows_outflows';
              onHover?.(hoveredDate, flow as FlowType);
            }
          }}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any -- Recharts ref type compatibility
          ref={chartRef as any}
        >
          <CartesianGrid stroke="var(--border-tertiary)" syncWithTicks vertical={false} />
          {!isLoading ? (
            <XAxis
              axisLine={false}
              dataKey="name"
              interval={0}
              padding={{ left: 20, right: 20 }}
              scale="point"
              tick={customXAxisTick}
              tickLine={false}
            />
          ) : null}
          <YAxis
            axisLine={false}
            dataKey="balance"
            domain={[minY, maxY]}
            interval={0}
            scale="linear"
            tick={
              isLoading
                ? loadingYAxisTick
                : (props: CustomTickProps) => yAxisTick({ ...props, formatNumber })
            }
            tickCount={4}
            tickLine={false}
            yAxisId="left"
          />
          <YAxis
            domain={['dataMin', (dataMax: number) => dataMax * 1.2]}
            hide
            scale="linear"
            yAxisId="right"
          />
          {!isLoading ? (
            <>
              <Line
                activeDot={hoveredIndex ? false : customActiveDot}
                className={
                  balanceAdjustment !== undefined ? styles['grey-line'] : styles['highlighted-line']
                }
                dataKey="balance"
                dot={balanceDot}
                isAnimationActive={false}
                strokeDasharray="4 4"
                strokeWidth={2}
                yAxisId="left"
              />
              {balanceAdjustment !== undefined && (
                <Line
                  activeDot={false}
                  className={styles['highlighted-line']}
                  dataKey="adjustedBalance"
                  dot={false}
                  isAnimationActive
                  stroke="var(--dataviz-balance-secondary, #7B61D6)" // Slightly different color
                  strokeDasharray="4 4"
                  strokeWidth={2}
                  yAxisId="left"
                />
              )}
            </>
          ) : null}

          <Tooltip
            content={
              <CustomTooltip
                activeTooltipIndex={activeTooltipIndex}
                dataLength={data.length}
                highlightedIndex={highlightedIndex}
                hoveredIndex={hoveredIndex}
                hoveredKey={hoveredKey}
                scheduledTransactionPreview={scheduledTransactionPreview}
              />
            }
            cursor={
              hoveredIndex !== null
                ? { opacity: 0 }
                : { stroke: 'var(--state-primary-a-hover)', strokeWidth: `${cursorStrokeWidth}px` }
            }
            wrapperStyle={{
              visibility: activeTooltipIndex !== null ? 'visible' : 'hidden',
            }}
          />
          {barConfigs.map(({ dataKey, className, testId }) => (
            <Bar
              animationDuration={isLoading || balanceAdjustment ? 0 : 300}
              animationEasing="ease-in-out"
              barSize={barSize}
              className={isLoading ? styles['skeleton-bar'] : className}
              data-testid={testId}
              dataKey={dataKey}
              key={dataKey}
              radius={[1, 1, 0, 0]}
              shape={
                <FlowBar
                  dataKey={dataKey}
                  hoveredIndex={hoveredIndex}
                  hoveredKey={hoveredKey}
                  opacity={adjustedBarStack.includes(dataKey) ? 1 : undefined}
                  setHoveredIndex={setHoveredIndex}
                  setHoveredKey={setHoveredKey}
                />
              }
              stackId={inflowsBarStack.includes(dataKey) ? 'a' : 'b'}
              yAxisId="right"
            >
              <LabelList
                // eslint-disable-next-line react/no-unstable-nested-components -- To be able to customize labels for styling
                content={({ x = 0, y = 0, value, index }) =>
                  index === hoveredIndex && dataKey === hoveredKey && value !== undefined ? (
                    <text
                      className={className}
                      data-testid={`${dataKey}-${index}-label`}
                      fontSize={12}
                      textAnchor="middle"
                      x={x as number}
                      y={(y as number) - 5}
                    >
                      {formatNumber(Number(value), {
                        style: 'currency',
                        currency: 'EUR',
                        notation: 'compact',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1,
                      })}
                    </text>
                  ) : null
                }
                dataKey={dataKey}
                position="top"
              />
            </Bar>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
