import type { ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './payment-detail-skeleton.strict-module.css';

export function PaymentDetailSkeleton(): ReactNode {
  return (
    <div className={styles.detail} data-testid="payment-detail-skeleton">
      <SkeletonLoader.Line className={styles.title} height="20px" width="159px" />

      <div className={styles.card}>
        <div className={styles.payment}>
          <SkeletonLoader.Line height="8px" width="139px" />
          <SkeletonLoader.Line height="8px" width="187px" />
          <SkeletonLoader.Line height="8px" width="252px" />
        </div>
        <div className={styles.reference}>
          <SkeletonLoader.Line height="8px" standalone width="119px" />
        </div>
      </div>
      <div className={styles.section}>
        <SkeletonLoader.Line height="8px" standalone width="90px" />
        <SkeletonLoader.Block height="40px" width="100%" />
      </div>

      <div className={styles.separator} />
      <div className={styles.date}>
        <SkeletonLoader.Line height="8px" width="90px" />
        <SkeletonLoader.Block height="40px" width="100%" />
        <div className={styles.cta}>
          <SkeletonLoader.Block height="32px" width="136px" />
          <SkeletonLoader.Block height="32px" width="80px" />
        </div>
      </div>
      <SkeletonLoader.Line height="8px" width="252px" />

      <div className={styles.separator} />

      <SkeletonLoader.Line height="20px" width="159px" />

      <div className={styles.section}>
        <SkeletonLoader.Line height="8px" standalone width="90px" />
        <SkeletonLoader.Block height="40px" width="100%" />
      </div>
    </div>
  );
}
