import type { SVGProps, ReactElement } from 'react';

export function IconDeclinedSmall(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height="12"
      viewBox="0 0 16 16"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8Z"
        fill="#B81818"
      />
      <path
        d="M8 12C6.93913 12 5.92172 11.5786 5.17157 10.8284C4.42143 10.0783 4 9.06087 4 8C4 6.93913 4.42143 5.92172 5.17157 5.17157C5.92172 4.42143 6.93913 4 8 4C9.06087 4 10.0783 4.42143 10.8284 5.17157C11.5786 5.92172 12 6.93913 12 8C12 9.06087 11.5786 10.0783 10.8284 10.8284C10.0783 11.5786 9.06087 12 8 12ZM11 8C11 7.37778 10.8044 6.77156 10.4409 6.26667L6.26667 10.44C6.71446 10.7601 7.24186 10.9505 7.79086 10.9903C8.33986 11.03 8.88919 10.9176 9.37845 10.6654C9.8677 10.4132 10.2779 10.031 10.564 9.56073C10.8501 9.09048 11.001 8.55044 11 8ZM5 8C5 8.62222 5.19556 9.22844 5.55911 9.73333L9.73422 5.55911C9.28636 5.23872 8.75879 5.0481 8.20958 5.00823C7.66036 4.96836 7.1108 5.08079 6.62136 5.33313C6.13192 5.58548 5.72159 5.96796 5.43553 6.43849C5.14947 6.90901 4.99876 7.44934 5 8Z"
        fill="white"
      />
    </svg>
  );
}
